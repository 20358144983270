import React from 'react';
// import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Layout } from '../components';
//import './404.scss';

export default function Page404 () {
  return (
    <Layout>
      <Helmet>
        <title>Not Found</title>
        <meta name="og:title" content="Not found" />
        <meta name="og:description" content="" />
        <meta name="og:image" content="" />
      </Helmet>

      <section className="page-not-found">
        <h4>Not found</h4>
      </section>
    </Layout>
  );
}
